import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/Layout/Layout"
import { Container, Table } from "react-bootstrap"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"

const ProgramPage = props => {
  const program = props.data.contentfulProgram

  return (
    <Layout>
      <SEO title={program.programName} />
      <div>
        <div className="page-header">
          <Container>
            <h1>OpenADR Program Profile</h1>
          </Container>
        </div>
      </div>
      <Container style={{ paddingTop: "70px", maxWidth: "875px" }}>
        <div
          style={{ width: "100%", textAlign: "center", marginBottom: "30px" }}
        >
          <h4>{program.programName}</h4>
          <Link
            to="/oadr-programs"
            style={{
              fontSize: "0.8em",
              fontWeight: "normal",
            }}
          >
            back to all programs
          </Link>
        </div>
        <div className="table-feature" style={{padding: "10px"}}>
          <Table>
            <tbody>
              <tr>
                <th className="table-header">Program Name</th>
                <td>
                  <a
                    href={program.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {program.programName}
                  </a>{" "}
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </td>
              </tr>
              <TableRow
                field="Utility Administrator"
                content={program.utilityAdministrator}
              />
              <TableRow
                field="Location(s)"
                content={program.locations.join(", ")}
              />
              <TableRow
                field="OpenADR Requirement"
                content={program.openAdrRequirement}
              />
              <TableRow
                field="Description"
                content={program.description.description}
              />
              <TableRow
                field="Sector(s)"
                content={<MultiLineContent content={program.sectors} />}
              />
              <TableRow
                field="Device Types(s)"
                content={<MultiLineContent content={program.deviceTypes} />}
              />
              <TableRow
                field="Incentive Types"
                content={program.incentiveTypes.join(", ")}
              />
              {program.incentives && (
                <TableRow
                  field="Description of Incentives"
                  content={
                    <MultiLineContent
                      content={program.incentives.incentives.split("\n")}
                    />
                  }
                />
              )}
              {program.equipmentRebates && (
                <TableRow
                  field="Description of Device Rebates"
                  content={
                    <MultiLineContent
                      content={program.equipmentRebates.equipmentRebates.split(
                        "\n"
                      )}
                    />
                  }
                />
              )}
            </tbody>
          </Table>
        </div>
      </Container>
    </Layout>
  )
}

const MultiLineContent = ({ content }) => <div>{content.map((item, index) => <p key={index}>{item}</p>)}</div>

const TableRow = ({ field, content }) => (
  <tr>
    <th className="table-header">{field}</th>
    <td>{content}</td>
  </tr>
)

export default ProgramPage

export const query = graphql`
         query ProgramQuery($slug: String!) {
           contentfulProgram(slug: { eq: $slug }) {
             utilityAdministrator
             programName
             programAdministrator
             openAdrRequirement
             locations
             deviceTypes
             link
             incentiveTypes
             incentives {
               incentives
             }
             equipmentRebates {
               equipmentRebates
             }
             description {
               description
             }
             slug
             sectors
           }
         }
       `
